import { SESSION } from '../constans/session'

const initialState = {
  message: null,
  status: null,
  entryForm: {},
  authStatus: null, // athenticated// notauthenticated
  user: {},
}

const registration = (state = initialState, action) => {
  switch (action.type) {
    case SESSION.SET_STATUS:
      return {
        ...state,
        message: action.message,
        status: action.status || 'success',
      }
    case SESSION.GET_USER:
      return {
        ...state,
        authStatus: 'request',
        user: {},
      }
    case SESSION.SET_USER:
      return {
        ...state,
        user: action.payload,
      }
    case SESSION.LOGIN:
      return {
        ...state,
        status: 'request',
        message: null,
      }
    case SESSION.LOGIN_SUCCESS:
      return {
        ...state,
        status: 'success',
        message: action.payload.data.message || 'Login Success',
      }
    case SESSION.LOGIN_FAILED:
      return {
        ...state,
        status: 'failed',
        message: action.payload.data.error,
      }
    case SESSION.UPDATE_USER:
      return {
        ...state,
        status: 'request',
        message: null,
      }
    case SESSION.UPDATE_USER_SUCCESS:
      return {
        ...state,
        status: 'success',
        message: action.payload.data.message || 'Update User Success',
      }
    case SESSION.UPDATE_USER_FAILED:
      return {
        ...state,
        status: 'failed',
        message: action.payload.data.error,
      }
    default:
      return state
  }
}

export default registration
