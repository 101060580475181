import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import nestedForm from './nested-form'
import registration from './registration'
import session from './session'
import company from './company'
import application from './application'

const persistNestedForm = {
  key: 'nested-form',
  whitelist: [],
  blacklist: [],
  storage: storage,
}

// const persistRegistration = {
//   key: 'registration-form',
//   whitelist: ['persistedValue'],
//   blacklist: [],
//   storage: storage,
// }

//registrationValue

const persistedNestedForm = persistReducer(persistNestedForm, nestedForm)
// const persistedRegistrationForm = persistReducer(persistRegistration, registration)

export default combineReducers({
  nestedForm: persistedNestedForm,
  registration,
  session,
  company,
  application,
})
