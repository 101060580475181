export const COMPANY = {
  GET_COMPANY: 'GET_COMPANY',
  GET_COMPANY_SUCCESS: 'GET_COMPANY_SUCCESS_SUCCESS',
  GET_COMPANY_FAILED: 'GET_COMPANY_SUCCESS_FAILED',

  DETAIL_COMPANY: 'DETAIL_COMPANY',
  DETAIL_COMPANY_SUCCESS: 'DETAIL_COMPANY_SUCCESS',
  DETAIL_COMPANY_FAILED: 'DETAIL_COMPANY_FAILED',

  GET_COMPANY_STRUCTURE: 'GET_COMPANY_STRUCTURE',
  GET_COMPANY_STRUCTURES_SUCCESS: 'GET_COMPANY_STRUCTURES_SUCCESS',
  GET_COMPANY_STRUCTURE_FAILED: 'GET_COMPANY_STRUCTURE_FAILED',

  GET_COMPANY_FINANCIAL: 'GET_COMPANY_FINANCIAL',
  GET_COMPANY_FINANCIAL_SUCCESS: 'GET_COMPANY_FINANCIAL_SUCCESS',
  GET_COMPANY_FINANCIAL_FAILED: 'GET_COMPANY_FINANCIAL_FAILED',

  GET_COMPANY_DOCUMENT: 'GET_COMPANY_DOCUMENT',
  GET_COMPANY_DOCUMENT_SUCCESS: 'GET_COMPANY_DOCUMENT_SUCCESS',
  GET_COMPANY_DOCUMENT_FAILED: 'GET_COMPANY_DOCUMENT_FAILED',
}
