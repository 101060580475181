import { webApi } from './config'

// new session
export const session = body => webApi().post('/sessions', {}, { data: body })

export const resendOtp = code => webApi().put(`/otp_sessions/${code}/resend`)

export const fetchProfile = () => webApi({ auth: true }).get('/user/current_user')

export const refreshToken = refreshToken =>
  webApi().post('/sessions', {
    grant_type: 'refresh_token',
    refresh_token: refreshToken,
  })

export const login = form => webApi().post('/sessions', form)

export const updateUser = form => webApi({ auth: true }).put('/user/current_user', form)

export const logout = () => webApi({ auth: true }).delete('/sessions')
