import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem } from 'reactstrap'

const PublicHeader = () => {
  const [isOpen, setOpen] = useState(false)
  const user = useSelector(state => state.session?.user)
  //   return 'e3m'
  return (
    <div className="public-header">
      <Navbar color="primary" light expand="md">
        <div className="container py-2">
          <NavbarBrand href="/">
            <img src="/assets/images/logo-dark.svg" style={{ height: 35 }} alt="" />
          </NavbarBrand>
          {_.isEmpty(user) && (
            <>
              <NavbarToggler onClick={() => setOpen(!isOpen)} />
              <Collapse isOpen={isOpen} navbar>
                <Nav className="ml-auto" navbar>
                  <NavItem>
                    <Link to="/login" className="btn btn-success btn-lg font-weight-bold">
                      Log In
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      to="/"
                      className="btn btn-outline-white border-white border-2 text-white ml-3 btn-lg font-weight-bold"
                    >
                      Register
                    </Link>
                  </NavItem>
                </Nav>
              </Collapse>
            </>
          )}
        </div>
      </Navbar>
    </div>
  )
}

export default PublicHeader
