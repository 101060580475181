import _ from 'lodash'
import 'flatpickr/dist/themes/material_blue.css'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'

const DateTimeField = ({ field, formSection, setFormSection, formName, errors }) => {
  const fieldName = formName + '.' + field.name

  return (
    <div className={`form-group col-md-${field.options.html_options.width || 12}`}>
      <label htmlFor={field.name} className="col-form-label font-weight-bold text-dark mb-0">
        {field.label}
        {field.validations.presence && <sup className="text-danger">*</sup>}
      </label>
      <Flatpickr
        value={null}
        data-enable-time
        readOnly={false}
        className="form-control"
        placeholder={field.hint}
        name={field.name}
        value={formSection?.[field.name] || ''}
        required={field.validations.presence}
        onChange={date => {
          setFormSection({
            [field.name]: moment(date[0]).toISOString(),
          })
        }}
      />
      {errors?.[fieldName] && <small className="text-danger">{_.upperFirst(errors?.[fieldName]?.join(', '))}</small>}
    </div>
  )
}

export default DateTimeField
