import { NESTED_FORM } from 'services/constans/nested-form'

const initialState = {
  clone: {},
}

const nestedForm = (state = initialState, action) => {
  switch (action.type) {
    case NESTED_FORM.START_CLONE:
      return {
        ...state,
        clone: {
          ...state.clone,
          [action.fieldName]: {
            targetField: action.targetField,
            sourceField: action.sourceField,
            data: 'PREPARE',
          },
        },
      }
    case NESTED_FORM.APPEND_CLONE:
      return {
        ...state,
        clone: {
          ...state.clone,
          [action.fieldName]: {
            ...(state.clone?.[action.fieldName] || {}),
            data: action.data,
          },
        },
      }
    case NESTED_FORM.FLUSH_CLONE:
      return {
        ...state,
        clone: {
          ...state.clone,
          [action.fieldName]: {
            ...(state.clone?.[action.fieldName] || {}),
            data: 'FLUSH',
          },
        },
      }
    case NESTED_FORM.CLEAR_CLONE:
      return {
        ...state,
        clone: Object.fromEntries(Object.entries(state.clone).filter(itm => itm[0] !== action.fieldName)),
      }
    default:
      return state
  }
}

export default nestedForm
