import Login from 'pages/public/login'
import Register from 'pages/public/index'
import RegisterOtp from 'pages/public/otp'
import RegisterCompany from 'pages/public/company'

import Dashboard from 'pages/dashboard'
import DashboardApplication from 'pages/dashboard/application'
import DashboardApplicationDetail from 'pages/dashboard/application/detail'
import DashboardCompany from 'pages/dashboard/company'
import DashboardAccount from 'pages/dashboard/account'

export default [
  {
    path: '/user/',
    exact: true,
    component: Dashboard,
  },
  {
    path: '/user/applications',
    exact: true,
    component: DashboardApplication,
  },
  {
    path: '/user/applications/:id',
    exact: true,
    component: DashboardApplicationDetail,
  },
  // {
  //   path: '/user/company',
  //   exact: true,
  //   component: DashboardCompany,
  // },
  {
    path: '/user/account',
    exact: true,
    component: DashboardAccount,
  },
  {
    path: '/login',
    exact: true,
    component: Login,
  },
  {
    path: '/',
    exact: true,
    component: Register,
  },
  {
    path: '/otp',
    exact: true,
    component: RegisterOtp,
  },
  {
    path: '/company',
    exact: true,
    component: RegisterCompany,
  },
]
