import { useEffect, useState } from 'react'
import _ from 'lodash'

const TextField = ({ field, formSection, setFormSection, formName, errors, lastForm }) => {
  const fieldName = formName + '.' + field.name

  const handleSetField = e => {
    setFormSection({
      [field?.name]: e.target.value,
    })
  }

  return (
    <div className={`form-group col-md-${field.options.html_options.width || 12}`}>
      <label htmlFor={field.name} className="col-form-label font-weight-bold text-dark mb-0">
        {field.label}
        {field.validations.presence && <sup className="text-danger">*</sup>}
      </label>
      {field?.options?.multiline ? (
        <textarea
          id={field.name}
          cols="30"
          rows="4"
          name={field.name}
          className="form-control"
          placeholder={field.hint}
          value={formSection?.[field.name] || ''}
          onChange={handleSetField}
          required={field.validations.presence}
        ></textarea>
      ) : (
        <input
          type="text"
          className="form-control"
          name={field.name}
          placeholder={field.hint}
          value={formSection?.[field.name] || ''}
          onChange={handleSetField}
          required={field.validations.presence}
        />
      )}
      {errors?.[fieldName] && <small className="text-danger">{_.upperFirst(errors?.[fieldName]?.join(', '))}</small>}
    </div>
  )
}

export default TextField
