import { REGISTRATION } from '../constans/registration'

export const register = form => ({
  type: REGISTRATION.REGISTER,
  form,
})

export const setStatus = (status, message) => ({
  type: REGISTRATION.SET_STATUS,
  status,
  message,
})

export const getEntryForm = () => ({
  type: REGISTRATION.GET_ENTRY_FORM,
})

export const setEntryForm = entryForm => ({
  type: REGISTRATION.SET_ENTRY_FORM,
  entryForm,
})

export const submitForm = (sectionId, form, formId, nextStep, lastFormId, successCallback, errorCallback) => ({
  type: REGISTRATION.SEND_ENTRY_FORM,
  sectionId,
  form,
  formId,
  lastFormId,
  nextStep,
  successCallback,
  errorCallback,
})

export const submitFormSuccess = payload => ({
  type: REGISTRATION.SEND_ENTRY_FORM_SUCCESS,
  payload,
})

export const submitFormFailed = payload => ({
  type: REGISTRATION.SEND_ENTRY_FORM_FAILED,
  payload,
})

export const getLastEnryForm = () => ({
  type: REGISTRATION.GET_LAST_ENTRY_FORM,
})

export const getLastEnrySuccess = payload => ({
  type: REGISTRATION.GET_LAST_ENTRY_FORM_SUCCESS,
  payload,
})

export const getLastEnryFailed = payload => ({
  type: REGISTRATION.GET_LAST_ENTRY_FORM_FAILED,
  payload,
})
