import React from 'react'

const resultSteps = {
  title: 'Result',
  position: 9999,
}

const Steps = ({ currentStep, sections }) => {
  const orderedSections = sections ? [...sections.sort((a, b) => a.position - b.position), resultSteps] : []
  return (
    <div>
      <ul className="list-unstyled d-flex register-step d-flex justify-content-center">
        {orderedSections.map((itm, key) => (
          <li key={key} className={`${currentStep > key ? 'active' : ''} `}>
            <span className="step-num">{key + 1}</span>
            <span className="step-title">{itm.title}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Steps
