import { SESSION } from 'services/constans/session'

export const submitOtp = body => ({
  type: SESSION.OTP,
  body,
})

export const setStatus = (status, message) => ({
  type: SESSION.SET_STATUS,
  status,
  message,
})

export const resendOtp = (code, callback) => ({
  type: SESSION.RESEND_OTP,
  code,
  callback,
})

// get user
export const getUser = () => ({
  type: SESSION.GET_USER,
})

export const setUser = payload => ({
  type: SESSION.SET_USER,
  payload,
})

export const refreshToken = () => ({
  type: SESSION.REFRESH_TOKEN,
})

// login

export const login = form => ({
  type: SESSION.LOGIN,
  form,
})

export const loginSuccess = payload => ({
  type: SESSION.LOGIN_SUCCESS,
  payload,
})

export const loginFailed = payload => ({
  type: SESSION.LOGIN_FAILED,
  payload,
})

// update user
export const updateUser = form => ({
  type: SESSION.UPDATE_USER,
  form,
})

export const updateUserSuccess = payload => ({
  type: SESSION.UPDATE_USER_SUCCESS,
  payload,
})

export const updateUserFailed = payload => ({
  type: SESSION.UPDATE_USER_FAILED,
  payload,
})

export const logout = () => ({
  type: SESSION.LOGOUT,
})
