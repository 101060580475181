import { all } from 'redux-saga/effects'

import nestedForm from './nested-form'
import registrationSaga from './registration'
import sessionSaga from './session'
import company from './company'
import application from './application'

const nestedFormSaga = [nestedForm()]
const registration = [registrationSaga(), sessionSaga()]

const users = [company(), application()]

function* rootSaga() {
  yield all([...nestedFormSaga, ...registration, ...users])
}

export default rootSaga
