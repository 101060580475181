export const REGISTRATION = {
  REGISTER: 'REGISTER',
  SET_STATUS: 'SET_STATUS',
  GET_ENTRY_FORM: 'GET_ENTRY_FORM',
  SET_ENTRY_FORM: 'SET_ENTRY_FORM',

  SEND_ENTRY_FORM: 'SEND_ENTRY_FORM',
  SEND_ENTRY_FORM_SUCCESS: 'SEND_ENTRY_FORM_SUCCESS',
  SEND_ENTRY_FORM_FAILED: 'SEND_ENTRY_FORM_FAILED',

  GET_LAST_ENTRY_FORM: 'GET_LAST_ENTRY_FORM',
  GET_LAST_ENTRY_FORM_SUCCESS: 'GET_LAST_ENTRY_FORM_SUCCESS',
  GET_LAST_ENTRY_FORM_FAILED: 'GET_LAST_ENTRY_FORM_SUCCESS',
}
