import React, { Suspense } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter, Switch, Route } from 'react-router-dom'
import './i18n'
import * as serviceWorker from './serviceWorker'

import routers from './router'
import Spinner from 'components/shared/spinner'
import NotfoundCompoennt from 'pages/errors/404'

const App = () => {
  return (
    <div className="container-scroller">
      <Suspense fallback={<Spinner />}>
        <Switch>
          {routers.map((route, key) => (
            <Route exact={route.exact} path={route.path} component={route.component} key={key} />
          ))}
          <Route component={NotfoundCompoennt} />
        </Switch>
      </Suspense>
    </div>
  )
}

serviceWorker.unregister()

export default withTranslation()(withRouter(App))
