import _ from 'lodash'
import { useDispatch } from 'react-redux'
import { flushClone, startClone } from 'services/actions/nested-form'

const TextField = ({ field, formSection, setFormSection, formName, errors }) => {
  const Dispatch = useDispatch()
  const fieldName = formName + '.' + field.name
  return (
    <div className={`form-group col-md-${field.options.html_options.width || 12}`}>
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id={field.name}
          checked={formSection?.[field.name] || false}
          required={field.validations.presence || field.validations.acceptance}
          onChange={e => {
            setFormSection({
              [field.name]: e.currentTarget.checked,
            })
            if (field.name === 'directors_as_guarantors') {
              if (e.currentTarget.checked) {
                Dispatch(startClone(field.name, 'key_director_particulars', 'guarantors_particulars'))
              } else {
                Dispatch(flushClone(field.name))
              }
            }
          }}
        />

        <label className="custom-control-label text-dark" htmlFor={field.name} required={field.validations.presence}>
          {field.label}
          {(field.validations.presence || field.validations.acceptance) && <sup className="text-danger">*</sup>}
        </label>
      </div>
      {errors?.[fieldName] && <small className="text-danger">{_.upperFirst(errors?.[fieldName]?.join(', '))}</small>}
    </div>
  )
}

export default TextField
