import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
      resources:{
          en: {
              translations: {
                "Welcome hiliteui dashboard!": "Welcome hiliteui dashboard!",
                "Search Here": "Search Here",
                "Activity Log": "Activity Log",
                "You have 7 unread mails": "You have 7 unread mails",
                "View all": "View all",
                "The meeting is cancelled": "The meeting is cancelled",
                "English": "English",
                "French": "French",
                "Arabic": "Arabic",
                "Russian": "Russian",
                "My Profile": "My Profile",
                "Messages": "Messages",
                "Activity": "Activity",
                "FAQ": "FAQ",
                "Administrator": "Administrator",
                "Sign Out": "Sign Out",
                "Dashboard": "Dashboard",
                "UI Elements": "UI Elements",
                "Basic UI Elements": "Basic UI Elements",
                "Accordions": "Accordions",
                "Buttons": "Buttons",
                "Badges": "Badges",
                "Breadcrumbs": "Breadcrumbs",
                "Dropdowns": "Dropdowns",
                "Modals": "Modals",
                "Progress bar": "Progress bar",
                "Pagination": "Pagination",
                "Tabs": "Tabs",
                "Typography": "Typography",
                "Tooltips": "Tooltips",
                "Advanced UI": "Advanced UI",
                "Clipboard": "Clipboard",
                "Context menu": "Context menu",
                "Sliders": "Sliders",
                "Carousel": "Carousel",
                "Loaders": "Loaders",
                "Form elements": "Form elements",
                "Basic Elements": "Basic Elements",
                "Advanced Elements": "Advanced Elements",
                "Validation": "Validation",
                "Wizard": "Wizard",
                "Editors": "Editors",
                "Text editors": "Text editors",
                "Code editors": "Code editors",
                "Charts": "Charts",
                "Tables": "Tables",
                "Basic Table":   "Basic Table",
                "Data Table":    "Data Table",
                "Sortable Table":"Sortable Table",
                "Popups": "Popups",
                "Notifications": "Notifications",
                "Icons": "Icons",
                "Maps": "Maps",
                "Extra Pages": "Extra Pages",
                "User Pages": "User Pages",
                "Login": "Login",
                "Login 2": "Login 2",
                "Register": "Register",
                "Register 2": "Register 2",
                "Lockscreen": "Lockscreen",
                "Error pages": "Error pages",
                "General Pages": "General Pages",
                "Blank Page": "Blank Page",
                "Profile": "Profile",
                "FAQ 2": "FAQ 2",
                "News Grid": "News Grid",
                "Timeline": "Timeline",
                "Search Results": "Search Results",
                "E-commerce": "E-commerce",
                "Invoice": "Invoice",
                "Pricing Table": "Pricing Table",
                "Orders": "Orders",
                "Applications": "Applications",
                "Apps": "Apps",
                "E-mail": "E-mail",
                "Calendar": "Calendar",
                "Help": "Help",
                "Documentation": "Documentation",
                "Sessions by channel": "Sessions by channel",
                "Total Leads": "Total Leads",
                "Assigned": "Assigned",
                "Not Assigned": "Not Assigned",
                "Reassigned": "Reassigned",
                "Performance Indicator": "Performance Indicator",
                "Complaints": "Complaints",
                "Task Done": "Task Done",
                "Attends": "Attends",
                "Quick Actions": "Quick Actions",
                "How are your active users trending overtime?": "How are your active users trending overtime?",
                "Add Client": "Add Client",
                "Create Quote": "Create Quote",
                "Enter Payment": "Enter Payment",
                "Create Invoice": "Create Invoice",
                "Products Inventory": "Products Inventory",
                "View all Products": "View all Products",
                "Store ID": "Store ID",
                "Amount": "Amount",
                "Gateway": "Gateway",
                "Created at": "Created at",
                "Paid at": "Paid at",
                "Status": "Status",
                "Jun": "Jun",
                "Jul": "Jul",
                "Aug": "Aug",
                "Sep": "Sep",
                "Oct": "Oct",
                "dec": "dec",
                "Paid": "Paid",
                "Pending": "Pending",
                "Failed": "Failed",
                "Showing 1 to 10 of 57 entries": "Showing 1 to 10 of 57 entries",
                "Latest Activity": "Latest Activity",
                "View more": "View more",
                "Mobile Apps Redesign": "Mobile Apps Redesign",
                "+23 since last year": "+23 since last year",
                "Inviting Join Apps Cont...": "Inviting Join Apps Cont...",
                "Website Redesign": "Website Redesign",
                "Analytics Dashboard": "Analytics Dashboard",
                "Great Logo Design": "Great Logo Design",
                "Sales Performance by Country": "Sales Performance by Country",
                "Export File": "Export File",
                "Print File": "Print File",
                "Sales performance of all states in the world": "Sales performance of all states in the world",
                "This is your most recent earnings for today's date.": "This is your most recent earnings for today's date.",
                "Activations": "Activations",
                "Updated": "Updated",
                "Net Revenue": "Net Revenue",
                "Income And Expenses Summary": "Income And Expenses Summary",
                "Total Income": "Total Income",
                "Total Expense": "Total Expense",
                "A comparison of people who mark themselves of their ineterest from the date range given above. Learn more.": "A comparison of people who mark themselves of their ineterest from the date range given above. Learn more.",
                "Twitter Followers": "Twitter Followers",
                "facebook likes": "facebook likes",
                "Instagram Posts": "Instagram Posts",
                "Report Summary": "Report Summary",
                "Updated Report": "Updated Report",
                "EXPENSE": "EXPENSE",
                "Reports": "Reports",
                "PURCHASE": "PURCHASE",
                "QUANTITY": "QUANTITY",
                "RETURN": "RETURN",
                "Sessions by Countries": "Sessions by Countries",
                "Netherlands": "Netherlands",
                "United States": "United States",
                "United kingdom": "United kingdom",
                "Canada": "Canada",
                "Australia": "Australia",
                "Quick Status": "Quick Status",
                "My Balance": "My Balance",
                "Sales Revenue": "Sales Revenue"
              }
          },
          ar: {
              translations: {
                "Welcome hiliteui dashboard!":   "مرحبا لوحة القيادة ممتاز!",
                "Search Here":                  "ابحث هنا",
                "You have 7 unread mails":      "لديك 7 رسائل غير مقروءة",
                "Activity Log":                 "سجلات النشاط",
                "Mark send you a message":      "مارك يرسل لك رسالة",
                "Cregh send you a message":    "كرغ يرسل لك رسالة",
                "Profile picture updated":      "اكتمل تحديث صورة الملف الشخصي",
                "4 new messages":               "4 رسائل جديدة",
                "Minutes ago":                  "دقائق مضت",
                "View all":                     " عرض الكل",
                "English":                      "إنجليزية",
                "French":                       "الفرنسية",
                "Arabic":                       "العربية",
                "Russian":                      "الروسية",
                "My Profile":                   "ملفي",
                "Messages":                     "رسائل",
                "Activity":                     "نشاط",
                "FAQ":                          "أسئلة مكررة",
                "Administrator":                "المدير رجل الإدارة",
                "Signout":                     "خروج",
                "Dashboard":                    "لوحة القيادة",
                "UI Elements":                  "عناصر واجهة المستخدم",
                "Basic UI Elements":            "عناصر واجهة المستخدم الأساسية",
                "Accordions":                   "أكورديون",
                "Buttons":                      "زر",
                "Badges":                       "شارات",
                "Breadcrumbs":                  "فتات الخبز",
                "Dropdowns":                    "قائِمة مُنْسَدِلة",
                "Modals":                       "الحوار",
                "Progress bar":                 "شريط التقدم",
                "Pagination":                   "ترقيم الصفحات",
                "Tabs":                         "علامات التبويب",
                "Typography":                   "الاسلوب والظهور",
                "Tooltips":                     "تلميح",
                "Advanced UI":                  "واجهة المستخدم المتقدمة",
                "Clipboard":                    "الحافظة",
                "Context menu":                 "قائمة السياق",
                "Sliders":                      "الانزلاق",
                "Carousel":                     "دائري",
                "Loaders":                      "رافعات",
                "Tree View":                    "شجرة البيانات",
                "Form Elements":                "نماذج",
                "Basic Elements":               "عناصر النموذج",
                "Advanced Elements":            "العناصر المتقدمة",
                "Validation":                   "التحقق من صحة",
                "Wizard":                       "ساحر",
                "Editors":                      "المحررين",
                "Text Editor":                 "محرري النصوص",
                "Code Editor":                 "محرري الكود",
                "Charts":                       "الرسوم البيانية",
                "Tables":                       "الطاولة",
                "Basic Table":                  "الجداول الأساسية",
                "Data Table":                   "جداول البيانات",
                "Sortable Table":               "الجداول القابلة للفرز",
                "Popups":                       "يظهر فجأة",
                "Notifications":                "إخطاراتs",
                "Icons":                        "الرموز",
                "Maps":                         "خرائط",
                "Extra Pages":                  "صفحات اضافية",
                "User Pages":                   "صفحات المستخدم",
                "Login":                        "تسجيل الدخول",
                "Login 2":                      " تسجيل الدخول 2 ",
                "Register":                     "سجل",
                "Register 2":                   " سجل 2 ",
                "Lockscreen":                   " قفل الشاشة ",
                "Error pages":                  "صفحات خطأ",
                "General Pages":                "الصفحات العامة",
                "Blank Page":                   " صفحة فارغة ",
                "Landing Page":                 "الصفحة المقصودة",
                "Profile":                      "الملف الشخصي ",
                "FAQ 2":                        " أسئلة مكررة 2 ",
                "News Grid":                    " شبكة الأخبار ",
                "Timeline":                     " الجدول الزمني ",
                "Search Results":               " نتائج البحث ",
                "User Listing":                 "قائمة المستخدم",
                "E-commerce":                   "التجارة الإلكترونية",
                "Invoice":                      " فاتورة ",
                "Pricing":                      " جدول التسعير ",
                "Orders":                       " طلب ",
                "Product Catalogue":            "كتالوج المنتج",
                "Project List":                 "قائمة المشروع",
                "Applications":                 "تطبيقات",
                "Apps":                         "تطبيقات",
                "Todo List":                    "قائمة المهام",
                "Tickets":                      "تذاكر",
                "Chats":                        "دردشات",
                "Gallery":                      "صالة عرض",
                "E-mail":                       "البريد الإلكتروني",
                "Calendar":                     "التقويم",
                "Help":                         "مساعدة",
                "Documentation":                "توثيق",
            
                "Sessions by channel":          "جلسات عن طريق القناة",
                "Total Leads":                  "مجموع العملاء المحتملين",
                "Assigned":                     "المخصصة لشخص ما",
                "Not Assigned":                 "لم يتم تخصيصه لأحد",
                "Reassigned":                   "إعادة التعيين لشخص آخر",
                "Performance Indicator":        "مؤشر الأداء",
                "Complaints":                   "شكاوي",
                "Task Done":                    "مهمة القيام به ",
                "Attends":                      "حضر",
                "Quick Actions":                "إجراءات سريعة",
            
                "How are your active users trending overtime?":     "كيف هي اتجاه المستخدمين النشطين مع مرور الوقت؟",
            
                "Add Client":                  "إضافة عميل",
                "Create Quote":                "خلق الاقتباس",
                "Enter Payment":               "أدخل الدفع",
                "Create Invoice":               "إنشاء فاتورة",
                "Products Inventory":           "مخزون المنتجات",
                "View all Products":            "عرض جميع المنتجات",
                "Store ID":                     "معرف المتجر",
                "Amount":                       "يستحق",
                "Gateway":                      "بوابة",
                "Created at":                   "أنشئت في",
                "Paid at":                      "المدفوعة على",
                "Status":                       "حالة",
                "Jun":                          "يونيو",
                "Jul":                          "يوليو",
                "Aug":                          "أغسطس",
                "Sep":                          "سبتمبر",
                "Oct":                          "شهر اكتوبر",
                "dec":                          "ديسمبر",
                "Paid":                         "تم الدفع",
                "Pending":                      "قيد الانتظار",
                "Failed":                       "فشلت",
                "Showing 1 to 10 of 57 entries":"إظهار 1 إلى 10 من أصل 57 مُدخل",
                "Latest Activity":              "النشاط الاخير",
                "View more":                    "شاهد المزيد",
                "Mobile Apps Redesign":"إعادة تصميم تطبيق الجوال",
                "+23 since last year":"+23 منذ العام الماضي",
                "Inviting Join Apps Cont...":"دعوة للتعاون على تطبيق...",
                "Website Redesign": "إعادة تصميم الموقع",
                "Analytics Dashboard": "تحليلات لوحة القيادة",
                "Great Logo Design": "تصميم شعار كبير",
                "Sales Performance by Country":     "أداء المبيعات حسب البلد",
                "Export File": " ملف التصدير",
                "Print File": "اطبع الملف",
                "Sales performance of all states in the world": "أداء المبيعات لجميع الدول في العالم",
                "This is your most recent earnings for today's date.": "هذا هو أحدث أرباحك لتاريخ اليوم.",
                "Activations": "التنشيط",
                "Updated": "محدث",
                "Net Revenue": "صافي الإيرادات",
                "Income And Expenses Summary": "ملخص الدخل والنفقات",
                "Total Income": " إجمالي الدخل ",
                "Total Expense": " المصروفات الكلية ",
                "A comparison of people who mark themselves of their ineterest from the date range given above. Learn more.": "مقارنة بين الأشخاص وفقًا للنطاق الزمني الموضح أعلاه. تعرف أكثر.",
                "Twitter Followers": "متابعين تويتر",
                "facebook likes": "اعجابات الفيسبوك",
                "Instagram Posts": "المشاركات انستغرام",
                "Report Summary": "ملخص تقرير",
                "Updated Report": "تقرير محدث",
                "Income": "الإيرادات",
                "Expense": "مصروف",
                "EXPENSE": "مصروف",
                "Reports": "تقارير",
                "PURCHASE": "شراء",
                "QUANTITY": "كمية",
                "RETURN": "إرجاع",
                "Sessions by Countries": "جلسات الدول",
                "Netherlands": "هولندا",
                "United States": "الولايات المتحدة الامريكيه",
                "United kingdom": "المملكة المتحدة",
                "Canada": "كندا",
                "Australia": "أستراليا",
                "Quick Status": "لمحة سريعة",
                "My Balance": "رصيدي",
                "Sales Revenue": "إيرادات المبيعات",
                "Hand-crafted & made with": "اليدوية والمصنوعة من",
                "Terms of use": "سياسة الخصوصية",
                "Privacy Policy": " شروط الاستخدام",
                "Copyright © 2019 hiliteui. All rights reserved.": "حقوق الطبع والنشر © 2019 hiliteui. كل الحقوق محفوظة."
            }
          }
      },
      fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

  export default i18n;