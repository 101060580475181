import { useReducer, useEffect, useState } from 'react'
import Renderer from './render'
import { FORM_BUILDER } from 'form-builder/constans'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { appendClone, clearClone } from 'services/actions/nested-form'
import { flush } from '@redux-saga/core/effects'

const MultipleNestedForm = ({ field, setFormSection, formSection, errors, lastForm, virtualForm }) => {
  const Dispatch = useDispatch()

  const formName = field.name + FORM_BUILDER.nestedform_suffix
  const manyLastForm = lastForm?.find(itm => itm.name === field.name)?.nested_form || []

  const cloneSetup = useSelector(state =>
    Object.fromEntries(
      [Object.entries(state.nestedForm.clone).find(ec => ec?.[1]?.sourceField === field?.name) || []].filter(
        a => a.length
      )
    )
  )

  const cloneTarget = useSelector(state =>
    Object.fromEntries(
      [Object.entries(state.nestedForm.clone).find(ec => ec?.[1]?.targetField === field?.name) || []].filter(
        a => a.length
      )
    )
  )

  const [form, setForm] = useState(formSection[formName] || [{}])
  const [virtualFormField, setVirtualForm] = useState([{}])

  const handleChangeForm = index => {
    return value => {
      const newForm = form.map((frm, idx) => {
        return idx === index
          ? {
              ...frm,
              ...value,
            }
          : frm
      })
      setForm(newForm)
    }
  }

  useEffect(() => {
    setFormSection({
      [formName]: form,
    })
  }, [form])

  useEffect(() => {
    if (manyLastForm.length) {
      if (manyLastForm?.[0]?.[0].id) {
        setForm(manyLastForm.map(mp => _.mapValues(_.keyBy(mp, 'name'), 'value')))
        setVirtualForm(manyLastForm.map(mp => _.mapValues(_.keyBy(mp, 'name'), 'value_for_preview')))
      }
    }
  }, [manyLastForm])

  useEffect(() => {
    if (field.name.includes('12') && field.name.includes('months') && field.name.includes('latest')) {
      console.log('revenue', field)
    }
  }, [field])

  useEffect(() => {
    const cloneObjName = Object.entries(cloneSetup)?.[0]?.[0]
    if (cloneObjName && cloneSetup?.[cloneObjName].data == 'PREPARE') {
      Dispatch(appendClone(cloneObjName, form))
    }
  }, [cloneSetup])

  useEffect(() => {
    const cloneObjName = Object.entries(cloneTarget)?.[0]?.[0]
    if (
      cloneTarget?.[cloneObjName] &&
      cloneTarget?.[cloneObjName]?.data !== 'PREPARE' &&
      cloneTarget?.[cloneObjName]?.data !== 'FLUSH'
    ) {
      // console.log('PUSH', field.name, cloneTarget)
      Dispatch(clearClone(cloneObjName))
      setForm(cloneTarget?.[cloneObjName]?.data)
    } else if (cloneTarget?.[cloneObjName]?.data === 'FLUSH') {
      Dispatch(clearClone(cloneObjName))
      const newForm = form.map(itm =>
        Object.fromEntries(Object.entries(itm).map(itm => [itm[0], Array.isArray(itm[1]) ? itm[1] : '']))
      )
      setForm(newForm)
    }
  }, [cloneTarget])

  return (
    <div className="w-100 mb-5">
      <div className="w-100 pb-2">
        <h3 className="text-secondary font-weight-normal">{field?.label}</h3>
        <div className="border-top mt-3"></div>
      </div>
      <div className="w-100">
        {form.map((frm, frmKey) => (
          <div className="w-100" key={frmKey}>
            <div className="row">
              {field.nested_form.fields.map((fld, key) => (
                <Renderer
                  field={fld}
                  key={key}
                  formSection={frm}
                  setFormSection={handleChangeForm(frmKey)}
                  formName={`${field.name}[${frmKey}]`}
                  errors={errors}
                  virtualForm={virtualFormField[frmKey] || {}}
                />
              ))}
            </div>
            {form?.length !== 1 && (
              <div className="w-100 mt-2">
                <button className="btn btn-danger" onClick={() => setForm(form.filter((_, key) => key !== frmKey))}>
                  Delete
                </button>
              </div>
            )}
          </div>
        ))}
        <div className="w-100 mt-3 d-flex justify-content-end">
          <button
            className="btn  btn-success d-flex align-items-center px-3 font-weight-bold"
            onClick={() => setForm([...form, {}])}
          >
            <i className="mdi mdi-plus"></i>
            <span className="ml-2">Add {field?.label}</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default MultipleNestedForm
