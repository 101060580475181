import { getApplicationSucces, getApplicationFailed } from 'services/actions/application'

import { call, takeLatest, put } from 'redux-saga/effects'
import { getApplications } from 'services/apis/application'
import { APPLICATION } from 'services/constans/application'

function* getApplicationSaga({ params }) {
  const getApplicationRequest = yield call(getApplications, params)
  if (getApplicationRequest.ok) {
    yield put(getApplicationSucces(getApplicationRequest))
  } else {
    yield put(getApplicationFailed(getApplicationRequest))
  }
}

function* watchSaga() {
  yield takeLatest(APPLICATION.GET_APPLICATION, getApplicationSaga)
}

export default watchSaga
