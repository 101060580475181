import _ from 'lodash'

const RadioField = ({ field, formSection, setFormSection, formName, errors }) => {
  const fieldName = formName + '.' + field.name
  return (
    <div className={`form-group col-md-${field.options.html_options.width || 12}`}>
      {/* {console.log(field)} */}
      <label htmlFor={field.name} className="col-form-label font-weight-bold text-dark mb-0">
        {field.label}
        {field.validations.presence && <sup className="text-danger">*</sup>}
      </label>

      {field.options.choices.map((choice, key) => (
        <div className="custom-control custom-radio" key={key}>
          <input
            type="radio"
            id={`${field.name}-${choice}`}
            name={field.name}
            className="custom-control-input"
            checked={formSection?.[field.name] === choice || false}
            onChange={e => {
              if (e.currentTarget.checked) {
                setFormSection({
                  [field.name]: choice,
                })
              }
            }}
          />
          <label className="custom-control-label text-dark" htmlFor={`${field.name}-${choice}`}>
            {choice}
          </label>
        </div>
      ))}
      {errors?.[fieldName] && <small className="text-danger">{_.upperFirst(errors?.[fieldName]?.join(', '))}</small>}
    </div>
  )
}

export default RadioField
