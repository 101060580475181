import React from 'react'
import { Link } from 'react-router-dom'

const RegisterSuccess = () => {
  return (
    <div>
      <div className="pt-5">
        <div className="w-100 d-flex justify-content-center">
          <i className="mdi mdi-check-circle text-success" style={{ fontSize: 120 }}></i>
        </div>
        <h2 className="text-center text-primary">Your Registration is Successfull</h2>
        <h4 className="text-dark text-center font-weight-normal mt-1">Your application is under review now</h4>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <Link to="/user" className="btn btn-success font-weight-bold d-flex align-items-center">
          Go to Dashboard
          <i className="mdi mdi-chevron-right"></i>
        </Link>
      </div>
    </div>
  )
}

export default RegisterSuccess
