import React, { useState, useReducer } from 'react'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { register as registerAction } from 'services/actions/registration'
import _ from 'lodash'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const registerSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phoneNumber: yup.string().required(),
  phoneCode: yup.string().required(),
  password: yup.string().required(),
  confirmPassword: yup.string().required(),
  consentOfTos: yup.bool().oneOf([true], 'This field is required'),
  consentOfPrivacyPolicy: yup.bool().oneOf([true], 'This field is required'),
  consentOfCraa: yup.bool().oneOf([true], 'This field is required'),
})

const RegisterHome = () => {
  const [newPasswordHide, setNewPasswordHide] = useState(true)
  const [confirmPasswordHide, setConfirmPasswordHide] = useState(true)
  const registration = useSelector(state => state.registration)
  const Dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerSchema),
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
      name: '',
      phoneNumber: '',
      phoneCode: '',
    },
  })

  const onSubmit = data => {
    Dispatch(
      registerAction({
        email: data.email,
        password: data.password,
        password_confirmation: data.confirmPassword,
        consent_of_tos: data.consentOfTos,
        consent_of_privacy_policy: data.consentOfPrivacyPolicy,
        consent_of_craa: data.consentOfCraa,
        profile_attributes: {
          name: data.name,
          mobile_phone_number: data.phoneCode + data.phoneNumber,
        },
      })
    )
  }

  return (
    <div>
      {/* {console.log(errors)} */}
      <h2 className="text-center text-primary">Register Your Account</h2>
      <h4 className="text-dark text-left font-weight-normal mt-3">
        Please fill in all fields. You will need the following documents:
        <ul className="mt-2">
          <li>
            <p>
              Clearly visible photos or scans of NRIC front & back or passport main page, for directors and guarantors
            </p>
          </li>
          <li>
            <p>
              SSM Company Profile PDF (please purchase from{' '}
              <a target="_blank" href="https://www.ssm-einfo.my/">
                https://www.ssm-einfo.my/
              </a>{' '}
              )
            </p>
          </li>
          <li>
            <p>
              CTOS consent form (please download from{' '}
              <a target="_blank" href="https://ctoscredit.com.my/">
                https://ctoscredit.com.my/
              </a>{' '}
              and print, sign, scan or use e-signature)
            </p>
          </li>
          <li>
            <p>PDF of latest management accounts</p>
          </li>
          <li>
            <p>PDF of latest audited accounts</p>
          </li>
          <li>
            <p>PDFs of latest 12 months bank statements</p>
          </li>
        </ul>
      </h4>
      <h4 className="text-dark text-center mt-3">
        Allready have an account ?{' '}
        <Link to="/login" className="text-decoration-none text-success">
          Login Here
        </Link>
      </h4>
      {(registration.status === 'error' || registration.status === 'success') && (
        <div className={`alert mt-3 alert-${registration.status === 'error' ? 'danger' : 'success'}`}>
          {registration.message}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-100 mt-4">
          <div className="form-group mb-2">
            <label htmlFor="" className="col-form-label font-weight-bold text-dark mb-0">
              Name
              <sup className="text-danger">*</sup>
            </label>
            <input type="text" className="form-control" placeholder="Enter name" {...register('name')} />
            {errors.name && <small className="text-danger">{_.upperFirst(errors.name.message)}</small>}
          </div>
          <div className="form-group mb-2">
            <label htmlFor="" className="col-form-label font-weight-bold text-dark mb-0">
              Email
              <sup className="text-danger">*</sup>
            </label>
            <input
              // type="email"
              className="form-control"
              placeholder="Enter email"
              {...register('email')}
            />
            {errors.email && <small className="text-danger">{_.upperFirst(errors.email.message)}</small>}
          </div>
          <div className="form-group">
            <label className="col-form-label text-dark font-weight-bold mb-0">Phone Number</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <select name="" className="form-control  bg-lighten" {...register('phoneCode')}>
                  <option value="+60">+60</option>
                  <option value="+62">+62</option>
                  <option value="+63">+63</option>
                </select>
              </div>
              <input
                // type="number"
                className="form-control"
                placeholder="Enter Phone Number"
                {...register('phoneNumber')}
              />
            </div>
            {errors.phoneNumber && <small className="text-danger">{_.upperFirst(errors.phoneNumber.message)}</small>}
          </div>
          <div className="form-group mb-2">
            <label htmlFor="" className="col-form-label font-weight-bold text-dark mb-0">
              Create New Password
            </label>
            <div className="password-input">
              <input type={newPasswordHide ? 'password' : 'text'} className="form-control" {...register('password')} />
              <button className="btn p-0" onClick={() => setNewPasswordHide(!newPasswordHide)}>
                <i className={`mdi ${newPasswordHide ? 'mdi-eye-off' : 'mdi-eye'} text-secondary`}></i>
              </button>
            </div>
            {errors.password && <small className="text-danger">{_.upperFirst(errors.password.message)}</small>}
          </div>
          <div className="form-group">
            <label htmlFor="" className="col-form-label font-weight-bold text-dark mb-0">
              Confirm New Password
            </label>
            <div className="password-input">
              <input
                type={confirmPasswordHide ? 'password' : 'text'}
                className="form-control"
                {...register('confirmPassword')}
              />
              <button className="btn p-0" onClick={() => setConfirmPasswordHide(!confirmPasswordHide)}>
                <i className={`mdi ${confirmPasswordHide ? 'mdi-eye-off' : 'mdi-eye'} text-secondary`}></i>
              </button>
            </div>
            {errors.confirmPassword && (
              <small className="text-danger">{_.upperFirst(errors.confirmPassword.message)}</small>
            )}
          </div>
          <div className="px-3">
            <div className="form-group mb-2">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="consentOfTos" {...register('consentOfTos')} />
                <label class="form-check-label text-dark ml-0 mb-0" for="consentOfTos">
                  I hereby agree with the <a href="">Terms and Conditions </a>
                </label>
              </div>
              {errors.consentOfTos && (
                <small className="text-danger">{_.upperFirst(errors.consentOfTos.message)}</small>
              )}
            </div>
            <div className="form-group mb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="consentOfPrivacyPolicy"
                  {...register('consentOfPrivacyPolicy')}
                />
                <label class="form-check-label text-dark ml-0 mb-0" for="consentOfPrivacyPolicy">
                  I hereby agree with the <a href="">Privacy Policy</a>
                </label>
              </div>
              {errors.consentOfPrivacyPolicy && (
                <small className="text-danger">{_.upperFirst(errors.consentOfPrivacyPolicy.message)}</small>
              )}
            </div>
            <div className="form-group mb-2">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="consentOfCraa"
                  value="true"
                  {...register('consentOfCraa')}
                />
                <label for="consentOfCraa" class="form-check-label text-dark ml-0 mb-0">
                  I hereby agree to provide my <a href="">consent</a> under the Credit Report Agency Act 2020
                </label>
              </div>
              {errors.consentOfCraa && (
                <small className="text-danger">{_.upperFirst(errors.consentOfCraa.message)}</small>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end mt-2">
          <button className="btn btn-success font-weight-bold" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default RegisterHome
