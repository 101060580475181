import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import JSCookie from 'js-cookie'
import { submitOtp, resendOtp } from 'services/actions/session'

const RegisterOtp = () => {
  const [verifyBy, setVerifyBy] = useState('email')
  const [otp, setOtp] = useState('')
  const maxOtpTime = 180 // as second
  const Dispatch = useDispatch()
  const [remainOtp, setRemainOtp] = useState(maxOtpTime)
  const currentSessionId = JSCookie.get('--app-session-id')
  const currentEmail = JSCookie.get('--app-user-email')
  const currentPhone = JSCookie.get('--app-user-phone')
  const currentAcessToken = JSCookie.get('--app-access-token')

  const registration = useSelector(state => state.registration)

  useEffect(() => {
    const createTimer = setInterval(() => {
      if (remainOtp > 0) {
        setRemainOtp(remainOtp - 1)
      }
    }, 1000)

    return () => {
      clearInterval(createTimer)
    }
  }, [remainOtp])

  const handleSubmitOtp = e => {
    e.preventDefault()
    const payload = {
      otp_session_id: currentSessionId,
      otp_attempt: otp,
    }
    Dispatch(submitOtp(payload))
  }

  return (
    <div>
      {!currentSessionId || (currentSessionId.length < 10 && <Redirect to="/" />)}
      {currentAcessToken && <Redirect to="/company" />}
      <h2 className="text-center text-primary">Verify Your Account</h2>
      <h4 className="text-dark text-center font-weight-normal mt-1">
        Please enter OTP code that was sent to your email
      </h4>

      <form action="" onSubmit={handleSubmitOtp}>
        <div className="w-100 mt-5 d-flex justify-content-center flex-column">
          {/* <div className="btn-group mx-auto">
          <button
            type="button"
            className={`btn ${
              verifyBy === 'email' ? 'btn-primary' : 'btn-outline-dark'
            } d-flex align-items-center py-3`}
            onClick={e => setVerifyBy('email')}
          >
            <i className="mdi mdi-email"></i>
            <span className="ml-2">By Email</span>
          </button>
          <button
            type="button"
            className={`btn ${
              verifyBy === 'phone' ? 'btn-primary' : 'btn-outline-dark'
            } d-flex align-items-center py-3`}
            onClick={e => setVerifyBy('phone')}
          >
            <i className="mdi mdi-cellphone"></i>
            <span className="ml-2">By Phone</span>
          </button>
        </div> */}
          <div className="row mt-3">
            {(registration.status === 'error' || registration.status === 'success') && (
              <div className={`alert mt-3 w-100 alert-${registration.status === 'error' ? 'danger' : 'success'}`}>
                {registration.message}
              </div>
            )}
            <div className="col-md-7 mx-auto">
              <div className="w-100 mt-3">
                {verifyBy === 'email' ? (
                  <p className="text-dark text-center">
                    We sent a 4 digits code to <span className="text-success">{currentEmail}</span>, please check your
                    email and insert the code below
                  </p>
                ) : (
                  <p className="text-dark text-center">
                    We sent a 4 digits code to <span className="text-success">{currentPhone}</span>, please check your
                    inbox an insert the code below
                  </p>
                )}
              </div>
              <div className="w-100 px-md-5 mt-3 justify-content-center">
                <OtpInput
                  value={otp}
                  inputStyle="form-control otp-input mx-2"
                  containerStyle="mx-auto justify-content-center"
                  onChange={setOtp}
                  isInputSecure
                  // shouldAutoFocus
                  numInputs={4}
                />
              </div>
              <div className="w-100 mt-3">
                <p className="text-center text-dark">Didn't receive verification code ?</p>
                <div className="d-flex justify-content-center">
                  <a
                    href="?"
                    disabled={remainOtp > 0}
                    className={`btn-link text-${remainOtp === 0 ? 'success' : 'secondary'}`}
                    onClick={e => {
                      e.preventDefault()
                      if (remainOtp === 0) {
                        Dispatch(
                          resendOtp(currentSessionId, () => {
                            setRemainOtp(maxOtpTime)
                          })
                        )
                      }
                    }}
                  >
                    Resend Code
                  </a>{' '}
                  <span className="text-secondary">({remainOtp}s)</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-2">
          <button type="submit" className="btn btn-success font-weight-bold" disabled={otp.length < 4}>
            Confirm
          </button>
        </div>
      </form>
    </div>
  )
}

export default RegisterOtp
