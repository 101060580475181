import { put, takeLatest, call, fork } from '@redux-saga/core/effects'
import { setStatus } from 'services/actions/registration'
import {
  setUser,
  loginSuccess,
  loginFailed,
  updateUserFailed,
  updateUserSuccess,
  logout,
} from 'services/actions/session'
import { SESSION } from 'services/constans/session'
import {
  session,
  resendOtp,
  fetchProfile,
  refreshToken as refreshTokenReq,
  login,
  updateUser,
} from 'services/apis/session'
import JScookie from 'js-cookie'

function* submitOtpSaga({ body }) {
  const submitOtpRequest = yield call(session, body)
  if (submitOtpRequest.ok) {
    yield put(setStatus('success', 'OTP Verified'))
    JScookie.remove('--app-session-id')
    JScookie.remove('--app-user-email')
    JScookie.remove('--app-user-phone')
    JScookie.set('--app-refresh-token', submitOtpRequest.data.data.refresh_token)
    JScookie.set('--app-access-token', submitOtpRequest.data.data.access_token)
    const cookieRedirect = JScookie.get('--app-otp-redirect')
    if (cookieRedirect) {
      JScookie.remove('--app-otp-redirect')
      window.location.href = cookieRedirect
    }
  } else {
    yield put(setStatus('error', submitOtpRequest.data.error || submitOtpRequest.data.message))
  }
}

function* resendOtpSaga({ code, callback }) {
  const resendOtpRequest = yield call(resendOtp, code)
  if (resendOtpRequest.ok) {
    JScookie.set('--app-session-id', resendOtpRequest?.data?.data?.otp_session_id)
    yield put(setStatus('success', 'OTP Resent'))
    yield callback()
  } else {
    yield put(setStatus('error', resendOtpRequest.data.error || resendOtpRequest.data.message))
  }
}

export function* refreshTokenSaga({ callback }) {
  const refreshToken = JScookie.get('--app-refresh-token')
  const refreshTokenRequest = yield call(refreshTokenReq, refreshToken)
  if (refreshTokenRequest.ok) {
    JScookie.set('--app-refresh-token', refreshTokenRequest.data.data.refresh_token)
    JScookie.set('--app-access-token', refreshTokenRequest.data.data.access_token)
    yield fork(callback)
  } else {
    JScookie.remove('--app-refresh-token')
    JScookie.remove('--app-access-token')
    window.location.href = '/'
  }
}

function* fetchUserSaga() {
  const getuserRequest = yield call(fetchProfile)
  if (getuserRequest.status === 401) {
    yield fork(refreshTokenSaga, { callback: fetchUserSaga })
  } else if (getuserRequest.ok) {
    yield put(setUser(getuserRequest.data.data))
  }
}

function* loginSaga({ form }) {
  const loginRequest = yield call(login, form)
  if (loginRequest.ok) {
    yield put(loginSuccess(loginRequest))
    if (loginRequest.data.data.otp_session_id) {
      JScookie.set('--app-session-id', loginRequest?.data?.data?.otp_session_id)
      JScookie.set('--app-otp-redirect', '/user')
      window.location.href = '/otp'
    } else {
      JScookie.set('--app-refresh-token', loginRequest.data.data.refresh_token)
      JScookie.set('--app-access-token', loginRequest.data.data.access_token)
      setTimeout(() => {
        window.location.href = '/user'
      }, 500)
    }
  } else {
    yield put(loginFailed(loginRequest))
  }
}

function* updateUserSaga({ form }) {
  const updateUserRequest = yield call(updateUser, form)
  if (updateUserRequest.ok) {
    yield put(updateUserSuccess(updateUserRequest))
    yield fork(fetchUserSaga)
  } else {
    yield put(updateUserFailed(updateUserRequest))
  }
}

function* logoutSaga() {
  yield call(logout)
  JScookie.remove('--app-refresh-token')
  JScookie.remove('--app-access-token')
  JScookie.remove('--app-session-id')
  JScookie.remove('--app-user-email')
  JScookie.remove('--app-user-phone')
  window.location.href = '/login'
}

function* watchSaga() {
  yield takeLatest(SESSION.OTP, submitOtpSaga)
  yield takeLatest(SESSION.RESEND_OTP, resendOtpSaga)
  yield takeLatest(SESSION.GET_USER, fetchUserSaga)
  yield takeLatest(SESSION.LOGIN, loginSaga)
  yield takeLatest(SESSION.UPDATE_USER, updateUserSaga)
  yield takeLatest(SESSION.LOGOUT, logoutSaga)
}

export default watchSaga
