import _ from 'lodash'
import { getFileSize, createBlob } from 'lib/file'
import { useEffect, useState } from 'react'

const FileUploader = ({ onChange }) => {
  return (
    <div className="file-uploader">
      <label htmlFor="">
        <i className="mdi mdi-cloud-upload text-success"></i>
        <span className="text-secondary">
          Drop files to upload or <u className="text-success">browse</u>{' '}
        </span>
        <input type="file" onChange={onChange} />
      </label>
    </div>
  )
}

const AttachmentField = ({ field, formSection, setFormSection, formName, errors, lastForm, virtualForm }) => {
  const fieldName = formName + '.' + field.name

  return (
    <div className={`form-group col-md-${field.options.html_options.width || 12}`}>
      {/* {console.log(field)} */}
      <label htmlFor={field.name} className="col-form-label font-weight-bold text-dark mb-0">
        {field.label}
        {field.validations.presence && <sup className="text-danger">*</sup>}
      </label>

      <FileUploader
        onChange={e => {
          setFormSection({ [field.name]: e.target.files[0] })
        }}
      />
      {/* {console.log(virtualForm)} */}

      {formSection?.[field.name] && (
        <ul className="file-list list-unstyled">
          <li className="d-flex py-2 border-bottom">
            {/* {console.log(formSection?.[field.name], formValueFetch)} */}
            <div className="file-icon">
              <i className="mdi mdi-file"></i>
            </div>
            <div className="file-name px-3 d-flex flex-column">
              <p className="text-dark mb-0">{formSection?.[field.name]?.name || virtualForm?.[field.name]}</p>
              {formSection?.[field.name]?.size && (
                <small className="file-size text-gray">{getFileSize(formSection?.[field.name]?.size)?.relative}</small>
              )}
            </div>
            <div className="file-action ml-auto">
              <button className="btn p-0" onClick={() => setFormSection({ [field.name]: null })}>
                <i className="mdi mdi-delete text-danger"></i>
              </button>
            </div>
          </li>
        </ul>
      )}
      {errors?.[fieldName] && <small className="text-danger">{_.upperFirst(errors?.[fieldName]?.join(', '))}</small>}
    </div>
  )
}

export default AttachmentField
