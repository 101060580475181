export const SESSION = {
  OTP: 'SESSION_OTP',
  SET_STATUS: 'SESSION_SET_STATUS',
  RESEND_OTP: 'SESSION_RESEND_OTP',

  // authtenticate
  GET_USER: 'SESSION_GET_USER',
  SET_USER: 'SESSION_SET_USER',
  REFRESH_TOKEN: 'REFRESH_TOKEN',

  LOGIN: 'SESSION_LOGIN',
  LOGIN_SUCCESS: 'SESSION_LOGIN_SUCCESS',
  LOGIN_FAILED: 'SESSION_LOGIN_FAILED',

  UPDATE_USER: 'SESSION_UPDATE_USER',
  UPDATE_USER_SUCCESS: 'SESSION_UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILED: 'SESSION_UPDATE_USER_FAILED',

  LOGOUT: 'SESSION_LOGOUT',
}
