import React, { useState } from 'react'

import RegisterStep from 'components/shared/register-step'
import PublicHeader from 'components/shared/public-header'
import RegisterHome from './register/index'
import JScookie from 'js-cookie'
import { Redirect } from 'react-router-dom'

const Register = () => {
  const [step, setStep] = useState(1)
  const hasAuthCookie = JScookie.get('--app-access-token')

  return (
    <div className="public-main">
      {hasAuthCookie && <Redirect to="/user" />}
      <PublicHeader />
      <div className="container">
        <div className="row">
          <div className="col-md-11 mx-auto my-5">
            <div className="card">
              <div className="card-body">
                {/* <RegisterStep currentStep={step} setStep={setStep} /> */}
                <div className="row">
                  <div className="col-md-10 mx-auto py-4">
                    {step === 1 && <RegisterHome currentStep={step} setStep={setStep} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register
