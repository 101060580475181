import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from 'app'
import './i18n'
import 'assets/styles/app.scss'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import reduxStore from 'services/store'

const MainApp = () => {
  return (
    <Provider store={reduxStore()}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  )
}

ReactDOM.render(<MainApp />, document.getElementById('root'))

serviceWorker.unregister()
