import { useReducer, useEffect, useState } from 'react'
import Renderer from './render'
import { submitForm, getLastEnryForm } from 'services/actions/registration'
import { useDispatch, useSelector } from 'react-redux'
// end

//Renderer
const Section = ({ setStep, step, formId, section, sections }) => {
  const [nextStep, setNextStep] = useState(null)
  const [previousStep, setPreviousStep] = useState(null)
  const registrationValue = useSelector(state => state.registration.persistedValue)
  const registrationStat = useSelector(state => state.registration)
  const lastFormData = useSelector(state => state.registration.lastFormData)
  const errors = useSelector(state => state.registration?.errors) || []
  const Dispatch = useDispatch()
  const [form, setForm] = useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    registrationValue[section.id] || {}
  )

  useEffect(() => {
    if (sections) {
      Dispatch(getLastEnryForm())
      const nextStep = sections.sort().filter(itm => itm.position > step)
      const previousStep = sections.sort().filter(itm => itm.position < step)
      setNextStep(nextStep?.[0]?.position || 'result')
      setPreviousStep(previousStep?.[0]?.position || 1)
    }
  }, [sections])

  const handleSubmitForm = e => {
    e?.preventDefault()
    Dispatch(
      submitForm(
        section.id,
        form,
        formId,
        nextStep,
        lastFormData?.id,
        () => {
          window.scrollTo({
            top: 0,
            left: 0,
          })
          setStep(nextStep)
        },
        () => {
          window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
          })
        }
      )
    )
  }
  return (
    <div className="list-section">
      <form onSubmit={handleSubmitForm}>
        {/* {console.log(JSON.stringify(form))} */}
        {!section.headless && (
          <div className="d-flex flex-column">
            <div
              className="section-title mx-auto text-dark"
              dangerouslySetInnerHTML={{ __html: section.title || '-' }}
            ></div>
            <div
              className="section-description mx-auto text-dark"
              dangerouslySetInnerHTML={{ __html: section.description || '-' }}
            ></div>
          </div>
        )}
        {registrationStat.status === 'success' && registrationStat.message?.length && (
          <div className="pt-3">
            <div className="alert alert-success">{registrationStat.message}</div>
          </div>
        )}
        {registrationStat.status === 'failed' && (
          <div className="pt-3">
            {Object.entries(registrationStat.message || {}).map((itm, key) => (
              <div key={key}>
                {itm[0] === 'error' && (
                  <div className="alert alert-danger" key={key}>
                    {itm[0]} : {itm[1].join(', ')}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <div className="w-100 mt-4">
          <div className="w-100">
            {section?.fields.map((field, key) => (
              <Renderer
                field={field}
                key={key}
                setFormSection={setForm}
                formSection={form}
                errors={errors}
                lastForm={lastFormData?.virtual_fields || []}
              />
            ))}
          </div>
        </div>
        <div className="d-flex mt-4">
          {step !== 1 && (
            <button
              className="btn btn-success font-weight-bold mr-auto d-flex align-items-center"
              onClick={e => setStep(previousStep)}
            >
              <i className="mdi mdi-chevron-left"></i>
              Previous
            </button>
          )}

          <button className="btn btn-success font-weight-bold ml-auto d-flex align-items-center">
            Next
            <i className="mdi mdi-chevron-right"></i>
          </button>
        </div>
      </form>
    </div>
  )
}

export default Section
