// text boolean decimal integer
// date datetime
// radio checkbox
// select multiple_select
// integer_range decimal_range date_range datetime_range
// nested_form multiple_nested_form attachment multiple_attachment

export const FORM_BUILDER = {
  formType: {
    text: 'Document::Fields::TextField',
    boolean: 'Document::Fields::BooleanField',
    decimal: 'Document::Fields::DecimalField',
    integer: 'Document::Fields::IntegerField',
    date: 'Document::Fields::DateField',
    datetime: 'Document::Fields::DatetimeField',
    radio: 'Document::Fields::RadioField',
    checkbox: 'Document::Fields::CheckboxField',
    select: 'Document::Fields::SelectField',
    multipleSelect: 'Document::Fields::MultipleSelectField',
    integerRange: 'Document::Fields::IntegerRangeField',
    decimalRange: 'Document::Fields::DecimalRangeField',
    // date_range: 'Document::Fields::DateRangeField',
    // datetimeRange: 'Document::Fields::DatetimeRangeField',
    attachment: 'Document::Fields::AttachmentField',
    multipleAttachment: 'Document::Fields::MultipleAttachmentField',
    nestedForm: 'Document::Fields::NestedFormField',
    multipleNestedForm: 'Document::Fields::MultipleNestedFormField',
  },

  accesibility: {
    readAndWrite: 'read_and_write',
    readonly: 'readonly',
    hidden: 'hidden',
  },
  nestedform_suffix: '_attributes',
}

// fields: {
//     company_attributes: { name: "company name", registration_number: "1111" }
//     key_directors_particulars_attributes: [{}, {}],
//     eula: "Yes"
//     }
