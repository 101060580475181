import { NESTED_FORM } from 'services/constans/nested-form'

export const startClone = (fieldName, sourceField, targetField) => ({
  type: NESTED_FORM.START_CLONE,
  fieldName,
  sourceField,
  targetField,
})

export const appendClone = (fieldName, data) => ({
  type: NESTED_FORM.APPEND_CLONE,
  fieldName,
  data,
})

export const flushClone = fieldName => ({
  type: NESTED_FORM.FLUSH_CLONE,
  fieldName,
})

export const clearClone = fieldName => ({
  type: NESTED_FORM.CLEAR_CLONE,
  fieldName,
})
