import { useReducer, useEffect, useState } from 'react'
import { FORM_BUILDER } from 'form-builder/constans'
import _ from 'lodash'
import moment from 'moment'

const create12Months = () => {
  let currentDay = moment().subtract(1, 'month')
  let listMonhts = []
  while (listMonhts.length < 12) {
    listMonhts.push(currentDay.format('MMMM'))
    currentDay = currentDay.subtract(1, 'month')
  }

  return listMonhts.reverse()
}

const LatestMonhts = ({ field, formSection, setFormSection, errors, lastForm }) => {
  const formName = field.name + FORM_BUILDER.nestedform_suffix

  lastForm = Array.isArray(lastForm) ? lastForm.find(itm => itm.period === field.name)?.nested_form || [] : []

  const [form, setForm] = useState([])

  useEffect(() => {
    if (Array.isArray(lastForm)) {
      const formLabel = field?.nested_form?.fields.reduce((acc, cp) => ({ ...acc, ...{ [cp.name]: null } }), {})
      const formList = lastForm
        .map(lF => lF.map(itm => ({ [itm.name]: itm.value })))
        .map(lF => lF.reduce((acc, cp) => ({ ...acc, ...cp }), {}))
      const finalMonths = create12Months().map(
        mt => formList.find(itm => itm.period?.toLowerCase() === mt?.toLowerCase()) || { ...formLabel, period: mt }
      )
      setForm(finalMonths)
    }
  }, [])

  const updateForm = (idx, value) => {
    setForm(form.map((fld, itm) => (itm === idx ? { ...fld, number: value } : fld)))
  }

  useEffect(() => {
    setFormSection({
      [formName]: form,
    })
  }, [form])

  return (
    <div>
      <div className="w-100 pb-2">
        <h3 className="text-secondary font-weight-normal">{field?.label}</h3>
        <div className="border-top mt-3"></div>
      </div>
      {/* {console.log(form)} */}
      <div className="row">
        {form?.map((itm, key) => (
          <div className="col-lg-4 col-md-6" key={key}>
            <div className="form-group">
              <label htmlFor={`months-${itm.period}`} className="col-form-label text-dark font-weight-bold">
                {itm.period}
              </label>
              <input
                type="text"
                id={`months-${itm.period}`}
                value={itm.number || ''}
                placeholder={`${itm.period}`}
                className="form-control"
                onChange={e => updateForm(key, e.currentTarget.value)}
              />
              {errors[`${field?.name}[${key}].number`] && (
                <small className="text-danger">
                  {_.upperFirst(errors[`${field?.name}[${key}].number`]?.join(', '))}
                </small>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default LatestMonhts
