import _ from 'lodash'
import { REGISTRATION } from '../constans/registration'

const initialState = {
  message: null,
  status: null,
  entryForm: {},
  persistedValue: {},
  lastFormData: {},
  errors: [],
}

const registration = (state = initialState, action) => {
  switch (action.type) {
    case REGISTRATION.SET_STATUS:
      return {
        ...state,
        message: action.message,
        status: action.status || 'success',
      }
    case REGISTRATION.SET_ENTRY_FORM:
      return {
        ...state,
        message: null,
        status: null,
        entryForm: action.entryForm,
      }
    case REGISTRATION.SEND_ENTRY_FORM:
      return {
        ...state,
        persistedValue: {
          ...state.persistedValue,
          [action.sectionId]: action.form,
        },
        status: 'request',
        message: null,
      }
    case REGISTRATION.SEND_ENTRY_FORM_SUCCESS:
      return {
        ...state,
        status: 'success',
        message: action.payload?.data?.message || 'Successfull submit',
      }
    case REGISTRATION.SEND_ENTRY_FORM_FAILED:
      return {
        ...state,
        status: 'failed',
        errors: !_.isEmpty(action?.payload?.data?.details) ? action?.payload?.data?.details : {},
        message: action?.payload?.data?.details || { error: ['Please upload all required documents'] },
      }
    // get last form
    case REGISTRATION.GET_LAST_ENTRY_FORM:
      return {
        ...state,
        status: 'request',
        message: null,
      }
    case REGISTRATION.GET_LAST_ENTRY_FORM_SUCCESS:
      return {
        ...state,
        status: 'success',
        message: null,
        lastFormData: action.payload,
      }
    case REGISTRATION.GET_LAST_ENTRY_FORM_FAILED:
      return {
        ...state,
        status: 'failed',
        message: action.payload.data.message || action.payload.data.error,
        lastFormData: {},
      }
    default:
      return state
  }
}

export default registration
