import { serialize } from 'object-to-formdata'
import JSCookie from 'js-cookie'

//append Bearer id token or access token to Authorization header
export const beforeRequestAppendAuthorizationHeader = (api, config) => {
  const authCookie = JSCookie.get('--app-access-token')
  if (authCookie) {
    config.headers['Cache-Control'] = `no-cache`
    config.headers['Authorization'] = `Bearer ${authCookie}`
  }
  return config
}

const dataToFormData = object => {
  const options = {
    indices: false,
    nullsAsUndefineds: false,
    booleansAsIntegers: false,
    allowEmptyArrays: true,
  }
  return serialize(object, options)
}

const setFormData = (config, formdata) => {
  config.formData = formdata
  return config
}

export const beforeRequestStoreFormData = (api, config) => {
  if (config.data) {
    if (config.data.formData) {
      config = setFormData(config, config.data.formData)
      delete config.data.formData
    }
  }
  return config
}

export const uploadRequest = (api, config) => {
  if (config.formData === true) {
    if (config.headers['Content-Type'] !== 'multipart/form-data') {
      config.headers['Content-Type'] = 'multipart/form-data'
      let formdata = config.data
      formdata = dataToFormData(formdata)
      config.data = formdata
    }
  }
  return config
}
