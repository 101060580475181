import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import StepForm from 'components/forms/step'
import PublicHeader from 'components/shared/public-header'
import Section from 'components/forms/section'
import { getEntryForm } from 'services/actions/registration'
import JSCookie from 'js-cookie'
import { Redirect } from 'react-router-dom'
import SuccessRegister from './register/success'
import AuthServiceProvider from 'providers/auth-service'

const CompanyRegiter = () => {
  const [step, setStep] = useState()
  const Dispatch = useDispatch()
  const entryForm = useSelector(state => state?.registration?.entryForm)

  useEffect(() => {
    Dispatch(getEntryForm())
  }, [])

  useEffect(() => {
    if (entryForm) {
      const firstSection = entryForm?.sections?.sort((a, b) => a.position - b.position)?.[0]?.position
      setStep(firstSection)
      // setStep(2)
    }
  }, [entryForm])

  return (
    <>
      <AuthServiceProvider>
        <div className="public-main">
          <PublicHeader />
          {/* {console.log(entryForm, step)} */}
          <div className="container">
            <div className="row">
              <div className="col-md-11 mx-auto my-5">
                <div className="card">
                  <div className="card-body">
                    <StepForm currentStep={step} setStep={setStep} sections={entryForm?.sections} />
                    <div className="row">
                      <div className="col-md-10 mx-auto py-4">
                        {entryForm?.sections?.map(
                          (sc, key) =>
                            step === sc.position && (
                              <Section
                                section={sc}
                                key={key}
                                setStep={setStep}
                                step={step}
                                formId={entryForm?.id}
                                sections={entryForm?.sections}
                              />
                            )
                        )}
                        {step === 'result' && <SuccessRegister />}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AuthServiceProvider>
    </>
  )
}

export default CompanyRegiter
