import { FORM_BUILDER } from 'form-builder/constans'

// forms components
import NestedForm from './nested-form'
import MultipleNestedForm from './multiple-nested-form'
import TextField from './template/text-field'
import BooleanField from './template/boolean-field'
import DecimalField from './template/decimal-field'
import IntegerField from './template/integer-field'
import DateField from './template/date-field'
import DateTimeField from './template/date-time-field'
import RadioField from './template/radio-field'
import CheckboxField from './template/checkbox-field'
import SelectField from './template/select-field'
import MultipleSelectField from './template/multiple-select-field'
import IntegerRangeField from './template/integer-range-field'
import DecimalRangeField from './template/decimal-range-field'
import AttachmentField from './template/attachment-field'
import MultipleAttachmentField from './template/multiple-attachment-field'
import Latest12Months from './template/special-field/latest-12-month'
// end form components

const FormRender = ({ field, formSection, setFormSection, formName, errors, lastForm, virtualForm }) => {
  return (
    <>
      {
        // special fields
        field.type === FORM_BUILDER.formType.attachment ? (
          <AttachmentField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            lastForm={lastForm}
            errors={errors}
            virtualForm={virtualForm}
          />
        ) : field.type === FORM_BUILDER.formType.multipleAttachment ? (
          <MultipleAttachmentField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            lastForm={lastForm}
            errors={errors}
            virtualForm={virtualForm}
          />
        ) : field.name.includes('12') && field.name.includes('months') && field.name.includes('latest') ? (
          <Latest12Months
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            lastForm={lastForm}
            errors={errors}
            virtualForm={virtualForm}
          />
        ) : // Normal fields
        field.type === FORM_BUILDER.formType.nestedForm ? (
          <NestedForm
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            errors={errors}
            lastForm={lastForm}
            virtualForm={virtualForm}
          />
        ) : field.type === FORM_BUILDER.formType.multipleNestedForm ? (
          <>
            <MultipleNestedForm
              field={field}
              formSection={formSection}
              setFormSection={setFormSection}
              formName={formName}
              errors={errors}
              lastForm={lastForm}
              virtualForm={virtualForm}
            />
          </>
        ) : field.type === FORM_BUILDER.formType.text ? (
          <TextField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            errors={errors}
            lastForm={lastForm}
            virtualForm={virtualForm}
          />
        ) : field.type === FORM_BUILDER.formType.boolean ? (
          <BooleanField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            errors={errors}
            lastForm={lastForm}
            virtualForm={virtualForm}
          />
        ) : field.type === FORM_BUILDER.formType.decimal ? (
          <DecimalField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            errors={errors}
            lastForm={lastForm}
            virtualForm={virtualForm}
          />
        ) : field.type === FORM_BUILDER.formType.integer ? (
          <IntegerField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            errors={errors}
            virtualForm={virtualForm}
            lastForm={lastForm}
          />
        ) : field.type === FORM_BUILDER.formType.date ? (
          <DateField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            errors={errors}
            lastForm={lastForm}
            virtualForm={virtualForm}
          />
        ) : field.type === FORM_BUILDER.formType.datetime ? (
          <DateTimeField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            errors={errors}
            lastForm={lastForm}
            virtualForm={virtualForm}
          />
        ) : field.type === FORM_BUILDER.formType.radio ? (
          <RadioField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            errors={errors}
            lastForm={lastForm}
            virtualForm={virtualForm}
          />
        ) : field.type === FORM_BUILDER.formType.checkbox ? (
          <CheckboxField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            lastForm={lastForm}
            virtualForm={virtualForm}
            errors={errors}
          />
        ) : field.type === FORM_BUILDER.formType.select ? (
          <SelectField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            lastForm={lastForm}
            errors={errors}
            virtualForm={virtualForm}
          />
        ) : field.type === FORM_BUILDER.formType.multipleSelect ? (
          <MultipleSelectField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            errors={errors}
            virtualForm={virtualForm}
          />
        ) : field.type === FORM_BUILDER.formType.integerRange ? (
          <IntegerRangeField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            lastForm={lastForm}
            errors={errors}
            virtualForm={virtualForm}
          />
        ) : field.type === FORM_BUILDER.formType.decimalRange ? (
          <DecimalRangeField
            field={field}
            formSection={formSection}
            setFormSection={setFormSection}
            formName={formName}
            lastForm={lastForm}
            errors={errors}
            virtualForm={virtualForm}
          />
        ) : (
          <>
            <div className="alert alert-danger mt-3">{field.type}</div>
          </>
        )
      }
    </>
  )
}

export default FormRender
