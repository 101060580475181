import _ from 'lodash'

const CheckboxField = ({ field, formSection, setFormSection, formName, errors }) => {
  const fieldName = formName + '.' + field.name

  return (
    <div className={`form-group col-md-${field.options.html_options.width || 12}`}>
      {/* {console.log(field)} */}
      <label htmlFor={field.name} className="col-form-label font-weight-bold text-dark mb-0">
        {field.label}
        {field.validations.presence && <sup className="text-danger">*</sup>}
      </label>

      {field.options.choices.map((choice, key) => (
        <div className="custom-control custom-checkbox" key={key}>
          <input
            type="checkbox"
            id={`${field.name}-${choice}`}
            name={field.name}
            className="custom-control-input"
            checked={formSection?.[field.name]?.indexOf(choice) >= 0}
            onChange={e => {
              let newVal = formSection?.[field.name] || []
              if (e.currentTarget.checked) {
                newVal.push(choice)
              } else {
                newVal = newVal.filter(vl => vl !== choice)
              }
              setFormSection({
                [field.name]: newVal,
              })
            }}
          />
          <label className="custom-control-label text-dark" htmlFor={`${field.name}-${choice}`}>
            {choice}
          </label>
        </div>
      ))}
      {errors?.[fieldName] && <small className="text-danger">{_.upperFirst(errors?.[fieldName]?.join(', '))}</small>}
    </div>
  )
}

export default CheckboxField
