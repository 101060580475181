import { useReducer, useEffect, useState } from 'react'
import Renderer from './render'
import { FORM_BUILDER } from 'form-builder/constans'
import { useSelector } from 'react-redux'

const NestedForm = ({ field, formSection, setFormSection, errors, lastForm }) => {
  const formName = field.name + FORM_BUILDER.nestedform_suffix
  const [virtualForm, setVirtualForm] = useState(formSection[formName] || [{}])

  const [form, setForm] = useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    formSection[formName] || {}
  )

  useEffect(() => {
    setFormSection({
      [formName]: form,
    })
  }, [form])

  useEffect(() => {
    // console.log(field.name, lastForm)
    if (lastForm?.length) {
      setForm(lastForm?.find(sfrm => sfrm.name === field.name)?.value)
    }
  }, [lastForm])

  return (
    <div className="mb-5">
      <div className="w-100 d-flex">
        <h3 className="text-secondary font-weight-normal">{field?.label}</h3>
      </div>
      <div className="row">
        {field.nested_form.fields.map((fld, key) => (
          <Renderer
            field={fld}
            key={key}
            formSection={form}
            setFormSection={setForm}
            formName={field.name}
            errors={errors}
            lastForm={lastForm?.find(frm => frm.name === field.name) || {}}
          />
        ))}
      </div>
    </div>
  )
}

export default NestedForm
