import React, { useState, useEffect } from 'react'
import { Link, withRouter, useLocation } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

const Sidebar = () => {
  const [menu, setMenu] = useState({})
  const location = useLocation()
  const user = useSelector(state => state.session.user)

  const toggleMenuState = menuState => {
    if (menu[menuState]) {
      setMenu({ [menuState]: false })
    } else if (Object.keys(menu).length === 0) {
      setMenu({ [menuState]: true })
    } else {
      Object.keys(menu).forEach(i => {
        setMenu({ [i]: false })
      })
      setMenu({ [menuState]: true })
    }
  }

  const handleRouteChange = () => {
    document.querySelector('#sidebar').classList.remove('active')
    Object.keys(menu).forEach(i => {
      setMenu({ [i]: false })
    })
  }
  const isPathActive = (path, exact = false) => {
    return exact ? location.pathname === path : location.pathname.startsWith(path)
  }

  useEffect(() => {
    handleRouteChange()
    const body = document.querySelector('body')
    document.querySelectorAll('.sidebar .nav-item').forEach(el => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
          // if (el.querySelector('.ReactCollapse--collapse')) {
          //   el.querySelector('.ReactCollapse--collapse').setAttribute('style', 'height: 0; overflow: hidden;');
          // }
        }
      })
    })
  }, [])

  useEffect(() => {
    handleRouteChange()
  }, [location])

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-profile">
          <div className="nav-link d-flex">
            <div className="profile-image">
              <img src="/assets/images/user.svg" alt="profile" />
            </div>
            <div className="profile-name">
              <p className="name">{user?.profile?.name || '-'}</p>
            </div>
          </div>
        </li>
        <li className={isPathActive('/user/applications') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/user/applications">
            <i className="mdi mdi-file-chart menu-icon"></i>
            <span className="menu-title">
              <Trans>Application</Trans>
            </span>
          </Link>
        </li>
        {/* <li className={isPathActive('/user/company') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/user/company">
            <i className="mdi mdi-city menu-icon"></i>
            <span className="menu-title">
              <Trans>Companies</Trans>
            </span>
          </Link>
        </li> */}
        <li className={isPathActive('/user/account') ? 'nav-item active' : 'nav-item'}>
          <Link className="nav-link" to="/user/account">
            <i className="mdi mdi-settings menu-icon"></i>
            <span className="menu-title">
              <Trans>Account</Trans>
            </span>
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Sidebar
