import { COMPANY } from 'services/constans/company'

export const getCompany = params => ({
  type: COMPANY.GET_COMPANY,
  params,
})

export const getCompanySucces = payload => ({
  type: COMPANY.GET_COMPANY_SUCCESS,
  payload,
})

export const getCompanyFailed = payload => ({
  type: COMPANY.GET_COMPANY_FAILED,
  payload,
})

// detail company

export const detailCompany = id => ({
  type: COMPANY.DETAIL_COMPANY,
  id,
})

export const detailCompanySucces = payload => ({
  type: COMPANY.DETAIL_COMPANY_SUCCESS,
  payload,
})

export const detailCompanyFailed = payload => ({
  type: COMPANY.DETAIL_COMPANY_FAILED,
  payload,
})

// company structure

export const getCompanyStructure = id => ({
  type: COMPANY.GET_COMPANY_STRUCTURE,
  id,
})

export const getCompanyStructureSucces = payload => ({
  type: COMPANY.GET_COMPANY_STRUCTURES_SUCCESS,
  payload,
})

export const getCompanyStructureFailed = payload => ({
  type: COMPANY.GET_COMPANY_STRUCTURE_FAILED,
  payload,
})

// financial

export const getFinancialReport = id => ({
  type: COMPANY.GET_COMPANY_FINANCIAL,
  id,
})

export const getFinancialReportSucces = payload => ({
  type: COMPANY.GET_COMPANY_FINANCIAL_SUCCESS,
  payload,
})

export const getFinancialReportFailed = payload => ({
  type: COMPANY.GET_COMPANY_FINANCIAL_FAILED,
  payload,
})

// documents

export const getCompanyDocument = id => ({
  type: COMPANY.GET_COMPANY_DOCUMENT,
  id,
})

export const getCompanyDocumentSucces = payload => ({
  type: COMPANY.GET_COMPANY_DOCUMENT_SUCCESS,
  payload,
})

export const getCompanyDocumentFailed = payload => ({
  type: COMPANY.GET_COMPANY_DOCUMENT_FAILED,
  payload,
})
