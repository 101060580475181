import { webApi, apiInstance } from './config'

// register
export const register = body =>
  webApi().post(
    '/registrations',
    {},
    {
      data: body,
    }
  )

// fetch dinamic form
export const getEntryForm = () => webApi().get('/registrations/entry_form')

export const submitEntryForm = fields =>
  webApi({ auth: true }).post(
    '/user/application_form_entries',
    {
      fields: fields || {},
    },
    {
      formData: true,
    }
  )

export const updateEntryForm = (fields, id) =>
  apiInstance.put(
    `/user/application_form_entries/${id}`,
    {
      fields: fields || {},
    },
    {
      formData: true,
    }
  )

export const submitFinalForm = formId => webApi({ auth: true }).put(`/user/application_form_entries/${formId}/submit`)

// last form
export const getLastEntryForm = () => webApi({ auth: true }).get('/user/application_form_entries')
