import _ from 'lodash'

const IntegerField = ({ field, formSection, setFormSection, formName, errors }) => {
  const fieldName = formName + '.' + field.name

  return (
    <div className={`form-group col-md-${field.options.html_options.width || 12}`}>
      <label htmlFor={field.name} className="col-form-label font-weight-bold text-dark mb-0">
        {field.label}
        {field.validations.presence && <sup className="text-danger">*</sup>}
      </label>
      <div className="d-flex">
        <span className="mr-auto text-dark">{field.options.begin_value}</span>
        <span className="ml-auto text-dark">{field.options.end_value}</span>
      </div>
      <input
        type="range"
        className="form-control-range"
        id={field.name}
        value={formSection?.[field.name] || field.options.begin_value}
        min={field.options.begin_value}
        max={field.options.end_value}
        required={field.validations.presence}
        onChange={e => {
          setFormSection({
            [field.name]: e.currentTarget.value,
          })
        }}
      />
      {errors?.[fieldName] && <small className="text-danger">{_.upperFirst(errors?.[fieldName]?.join(', '))}</small>}
    </div>
  )
}

export default IntegerField
