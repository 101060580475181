import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from 'services/actions/session'

const AuthServiceProvider = ({ children }) => {
  const Dispatch = useDispatch()
  useEffect(() => {
    Dispatch(getUser())
  }, [])

  return <>{children}</>
}

export default AuthServiceProvider
