import _ from 'lodash'
import Select from 'react-select'
import { useEffect } from 'react'

const SelectField = ({ field, formSection, setFormSection, formName, errors, lastForm }) => {
  const options = field.options.choices.map(itm => ({ value: itm, label: itm }))
  const fieldName = formName + '.' + field.name
  return (
    <div className={`form-group col-md-${field.options.html_options.width || 12}`}>
      {/* {console.log(field)} */}
      <label htmlFor={field.name} className="col-form-label font-weight-bold text-dark mb-0">
        {field.label}
        {field.validations.presence && <sup className="text-danger">*</sup>}
      </label>
      <Select
        options={options}
        value={options.find(fd => fd.value === formSection?.[field.name]) || null}
        required={field.validations.presence}
        onChange={value => {
          setFormSection({ [field.name]: value.value })
        }}
      />
      {errors?.[fieldName] && <small className="text-danger">{_.upperFirst(errors?.[fieldName]?.join(', '))}</small>}
    </div>
  )
}

export default SelectField
