import _ from 'lodash'

const DecimalField = ({ field, formSection, setFormSection, formName, errors }) => {
  const fieldName = formName + '.' + field.name

  return (
    <div className={`form-group col-md-${field.options.html_options.width || 12}`}>
      <label htmlFor={field.name} className="col-form-label font-weight-bold text-dark mb-0">
        {field.label}
        {field.validations.presence && <sup className="text-danger">*</sup>}
      </label>
      <input
        type="number"
        step={field?.options?.step}
        className="form-control"
        name={field.name}
        placeholder={field.hint}
        value={parseFloat(formSection?.[field.name] || null)}
        onChange={e => setFormSection({ [field.name]: parseFloat(e.currentTarget.value) })}
        required={field.validations.presence}
      />
      {errors?.[fieldName] && <small className="text-danger">{_.upperFirst(errors?.[fieldName]?.join(', '))}</small>}
    </div>
  )
}

export default DecimalField
