import _ from 'lodash'

import { getFileSize, createBlob } from 'lib/file'

const FileUploader = ({ onChange }) => {
  return (
    <div className="file-uploader">
      <label htmlFor="">
        <i className="mdi mdi-cloud-upload text-success"></i>
        <span className="text-secondary">
          Drop files to upload or <u className="text-success">browse</u>{' '}
        </span>
        <input type="file" onChange={onChange} multiple />
      </label>
    </div>
  )
}

const AttachmentField = ({ field, formSection, setFormSection, formName, errors }) => {
  const fieldName = formName + '.' + field.name

  return (
    <div className={`form-group col-md-${field.options.html_options.width || 12}`}>
      {/* {console.log(field)} */}
      <label htmlFor={field.name} className="col-form-label font-weight-bold text-dark mb-0">
        {field.label}
        {field.validations.presence && <sup className="text-danger">*</sup>}
      </label>
      <FileUploader
        onChange={e => {
          const uniqueFile = [
            ...new Set([...Array.from(formSection?.[field.name] || []), ...Array.from(e.currentTarget.files)]),
          ]
          setFormSection({ [field.name]: uniqueFile })
        }}
      />
      {formSection?.[field.name] &&
        Array.from(formSection?.[field.name]).map((file, key) => (
          <ul className="file-list list-unstyled" key={key}>
            <li className="d-flex py-2 border-bottom">
              <div className="file-icon">
                <i className="mdi mdi-file"></i>
              </div>
              <div className="file-name px-3 d-flex flex-column">
                <p className="text-dark mb-0">{file?.name} </p>
                <small className="file-size text-gray">{getFileSize(file?.size)?.relative}</small>
              </div>
              <div className="file-action ml-auto">
                <button
                  className="btn p-0"
                  onClick={() =>
                    setFormSection({ [field.name]: formSection?.[field.name].filter(itm => itm.name !== file.name) })
                  }
                >
                  <i className="mdi mdi-delete text-danger"></i>
                </button>
              </div>
            </li>
          </ul>
        ))}
      {errors?.[fieldName] && <small className="text-danger">{_.upperFirst(errors?.[fieldName]?.join(', '))}</small>}
    </div>
  )
}

export default AttachmentField
