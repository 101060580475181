import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getCompanyStructure } from 'services/actions/company'
import _ from 'lodash'
import moment from 'moment'

const CompanyInformation = () => {
  const Dispatch = useDispatch()
  const { id } = useParams()
  const companyStructure = useSelector(state => state.company.structure)

  useEffect(() => {
    Dispatch(getCompanyStructure(id))
  }, [])

  return (
    <div className="col-md-8 col-12 mx-auto mt-4">
      {/* Director */}
      {companyStructure?.map((cs, key) => (
        <div className="w-100 mb-3" key={key}>
          <h3 className="text-secondary font-weight-normal">{_.startCase(_.toLower(cs.position))}</h3>
          <div className="w-100 border-top"></div>
          <div className="mt-3">
            <table className="table table-borderless mx-n3">
              <tbody>
                <tr>
                  <td style={{ width: 200 }}>
                    <span className="text-dark font-weight-bold">Name</span>
                  </td>
                  <td>
                    <span>{cs.name}</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 200 }}>
                    <span className="text-dark  font-weight-bold">NRIC</span>
                  </td>
                  <td>
                    <span>{cs.nric}</span>
                  </td>
                </tr>
                <tr>
                  <td style={{ width: 200 }}>
                    <span className="text-dark  font-weight-bold">Passport</span>
                  </td>
                  <td>
                    <span>{cs.passport_number}</span>
                  </td>
                </tr>

                <tr>
                  <td style={{ width: 200 }}>
                    <span className="text-dark  font-weight-bold">Created</span>
                  </td>
                  <td>
                    <span>{moment(cs.created_at).format('YYYY-MM-DD hh:mm A')}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    {cs?.upload?.file_url?.includes('pdf') ? (
                      <div className="pdf-wrapper d-flex flex-column justify-content-center align-items-center">
                        <img src="/assets/images/pdf.png" alt="" style={{ height: 90, width: 90, borderRadius: 0 }} />
                        <a
                          href={
                            !cs?.upload?.file_url?.includes('http')
                              ? `https://${cs?.upload?.file_url}`
                              : cs?.upload?.file_url
                          }
                          target="_blank"
                          className="btn btn-primary mt-3"
                        >
                          View
                        </a>
                      </div>
                    ) : (
                      <img
                        src={
                          !cs?.upload?.file_url?.includes('http')
                            ? `https://${cs?.upload?.file_url}`
                            : cs?.upload?.file_url
                        }
                        alt=""
                        style={{ objectFit: 'cover', borderRadius: 0, height: 150, width: 250 }}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CompanyInformation
