import JSCookie from 'js-cookie'
import axios from 'axios'
import { create } from 'apisauce'
import axiosRetry from 'axios-retry'
import * as interceptors from './interceptors'

export const webApi = ({ auth } = {}) => {
  const authCookie = JSCookie.get('--app-access-token')
  const baseApi = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      ...(auth && authCookie ? { Authorization: `Bearer ${authCookie}` } : {}),
    },
  })
  baseApi.interceptors.request.use(
    config => {
      config = interceptors.beforeRequestAppendAuthorizationHeader(api, config)
      config = interceptors.beforeRequestStoreFormData(api, config)
      config = interceptors.uploadRequest(api, config)
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
  axiosRetry(baseApi, { retryDelay: axiosRetry.exponentialDelay })
  return create({
    axiosInstance: baseApi,
    timeout: 20000,
  })
}

const opts = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
}

const api = axios.create(opts)

api.interceptors.request.use(
  config => {
    config = interceptors.beforeRequestAppendAuthorizationHeader(api, config)
    config = interceptors.beforeRequestStoreFormData(api, config)
    config = interceptors.uploadRequest(api, config)
    return config
  }
  // error => {
  //   return Promise.reject(error)
  // }
)

export const apiInstance = create({
  axiosInstance: api,
})
