import { put, takeLatest, call, takeEvery } from '@redux-saga/core/effects'
import {
  setStatus,
  setEntryForm,
  submitFormFailed,
  submitFormSuccess,
  getLastEnrySuccess,
  getLastEnryFailed,
} from 'services/actions/registration'
import { REGISTRATION } from 'services/constans/registration'
import {
  register,
  getEntryForm,
  submitEntryForm,
  submitFinalForm,
  getLastEntryForm,
  updateEntryForm,
} from 'services/apis/registration'
import JScookie from 'js-cookie'

function* registerSaga({ form }) {
  yield put(setStatus('request', ''))
  const registerRequest = yield call(register, form)
  if (registerRequest.ok) {
    yield put(setStatus('success', 'Successfull register'))
    JScookie.set('--app-session-id', registerRequest?.data?.data?.otp_session_id)
    JScookie.set('--app-user-email', form?.email)
    JScookie.set('--app-user-phone', form?.profile_attributes?.mobile_phone_number)
    window.location.href = '/otp'
  } else {
    yield put(setStatus('error', registerRequest.data.message))
  }
}

function* getEntryFormSaga() {
  const entryFormRequest = yield call(getEntryForm)
  if (entryFormRequest.ok) {
    yield put(setEntryForm(entryFormRequest.data.data))
  } else {
    yield put(setStatus('error', entryFormRequest.data.message))
  }
}

function* submitEntryFormSaga({ form, formId, nextStep, lastFormId, successCallback, errorCallback }) {
  console.log(form, formId)
  // try {
  const submitRequest = yield call(lastFormId ? updateEntryForm : submitEntryForm, form, lastFormId)

  if (submitRequest.ok || submitRequest.status === 200) {
    yield put(submitFormSuccess(submitRequest))
    if (nextStep === 'result') {
      const submitRegistrationFormReguest = yield call(submitFinalForm, lastFormId)
      if (submitRegistrationFormReguest) {
        if (submitRegistrationFormReguest.ok) {
          yield successCallback()
        } else {
          yield put(submitFormFailed(submitRegistrationFormReguest))
        }
      } else {
        yield successCallback()
      }
    } else {
      yield successCallback()
    }
  } else {
    console.log(submitRequest)
    yield put(submitFormFailed(submitRequest))
    yield errorCallback()
  }
  // } catch (err) {
  //   yield put(submitFormFailed(err))
  // }
}

function* getLastEntryFormSaga() {
  const lastEntryFormRequest = yield call(getLastEntryForm)
  if (lastEntryFormRequest.ok) {
    yield put(getLastEnrySuccess(lastEntryFormRequest.data.data))
  } else {
    yield put(getLastEnryFailed(lastEntryFormRequest))
  }
}

function* watchSaga() {
  yield takeLatest(REGISTRATION.REGISTER, registerSaga)
  yield takeLatest(REGISTRATION.GET_ENTRY_FORM, getEntryFormSaga)
  yield takeLatest(REGISTRATION.SEND_ENTRY_FORM, submitEntryFormSaga)
  yield takeEvery(REGISTRATION.GET_LAST_ENTRY_FORM, getLastEntryFormSaga)
}

export default watchSaga
