import {
  getCompanyFailed,
  getCompanyStructureFailed,
  getCompanyStructureSucces,
  getCompanySucces,
  getFinancialReportFailed,
  getFinancialReportSucces,
  getCompanyDocumentFailed,
  getCompanyDocumentSucces,
} from 'services/actions/company'

import { call, takeLatest, put } from 'redux-saga/effects'
import {
  getCompany,
  getCompanyFinancial,
  getCompanyStructure as getCompanyStructureApi,
  getCompanyDocument,
} from 'services/apis/company'
import { COMPANY } from 'services/constans/company'

function* getCompanySaga({ params }) {
  const getCompanyRequest = yield call(getCompany, params)
  if (getCompanyRequest.ok) {
    yield put(getCompanySucces(getCompanyRequest))
  } else {
    yield put(getCompanyFailed(getCompanyRequest))
  }
}

function* getCompanyDetailSaga({ id }) {
  // const detailCompanyRequest = yield call(getCompanyDetail, id)
  // if (detailCompanyRequest.ok) {
  //   yield put(detailCompanySucces(detailCompanyRequest))
  // } else {
  //   yield put(detailCompanyFailed(detailCompanyRequest))
  // }
}

function* getCompanyStructureSaga({ id }) {
  const getCompanyStructureRequest = yield call(getCompanyStructureApi, id)
  if (getCompanyStructureRequest.ok) {
    yield put(getCompanyStructureSucces(getCompanyStructureRequest))
  } else {
    yield put(getCompanyStructureFailed(getCompanyStructureRequest))
  }
}

function* getCompanyFinancialSaga({ id }) {
  const getCompanyFinancialRequest = yield call(getCompanyFinancial, id)
  if (getCompanyFinancialRequest.ok) {
    yield put(getFinancialReportSucces(getCompanyFinancialRequest))
  } else {
    yield put(getFinancialReportFailed(getCompanyFinancialRequest))
  }
}

function* getCompanyDocumentSaga({ id }) {
  console.log('demo ', id)
  const getCompanyDocumentRequest = yield call(getCompanyDocument, id)
  if (getCompanyDocumentRequest.ok) {
    yield put(getCompanyDocumentSucces(getCompanyDocumentRequest))
  } else {
    yield put(getCompanyDocumentFailed(getCompanyDocumentRequest))
  }
}

function* watchSaga() {
  yield takeLatest(COMPANY.GET_COMPANY, getCompanySaga)
  yield takeLatest(COMPANY.DETAIL_COMPANY, getCompanyDetailSaga)
  yield takeLatest(COMPANY.GET_COMPANY_STRUCTURE, getCompanyStructureSaga)
  yield takeLatest(COMPANY.GET_COMPANY_FINANCIAL, getCompanyFinancialSaga)
  yield takeLatest(COMPANY.GET_COMPANY_DOCUMENT, getCompanyDocumentSaga)
}

export default watchSaga
