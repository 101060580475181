export const getFileSize = (size, fixed = 2) => {
  const asBit = size?.toFixed(fixed)
  const asKiloBit = (size / 1024).toFixed(fixed)
  const asMegaBit = (size / 1024 / 1024).toFixed(fixed)
  const asGigaBit = (size / 1024 / 1024 / 1024).toFixed(fixed)
  return {
    relative:
      asGigaBit > 1
        ? `${asGigaBit}gb`
        : asMegaBit > 1
        ? `${asMegaBit}mb`
        : asKiloBit > 1
        ? `${asKiloBit}kb`
        : `${asBit}b`,
    b: asBit,
    kb: asKiloBit,
    mb: asMegaBit,
    gb: asGigaBit,
  }
}

export const createBlob = file => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.readAsDataURL(file)
  })
}
