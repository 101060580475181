import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

export class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return <Redirect to="/user/account" />
  }
}

export default Dashboard
